.content-loader {
  filter: none;
  transition: filter 0.2s linear;
  min-height: 80px;

  @include screen-xs-min {
    min-height: 500px;
  }

  &--loading {
    -webkit-filter: blur(5px);
  }

  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.1s linear;
    pointer-events: none;

    &--visible {
      opacity: 1;
    }
  }
}
