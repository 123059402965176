.mobile-modal-view {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 100vh;
  left: 0;
  background-color: white;
  z-index: 101;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  overflow: auto;
  padding-bottom: 80px;

  &__overlay {
    position: fixed;
    background-color: rgb(166, 166, 166);
    width: 100%;
    height: 100%;
    top: -80px;
    left: 0;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
  }

  &__wrapper.is-visible {
    .mobile-modal-view {
      top: 80px;
      opacity: 1;
    }

    .mobile-modal-view__overlay {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__submit {
    &__wrapper {
      pointer-events: none;
      bottom: -60px;
      left: 0;
      padding-block: 10px;
      position: sticky;
      background-color: white;
      width: 100%;
      padding-inline: 10px;
      margin-top: 30px;
    }

    &__button {
      pointer-events: all;
      width: 100%;
      margin: 0 auto;
      display: block;
      max-width: 500px;
      position: relative;
      z-index: 1;
    }
  }
}
