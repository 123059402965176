.footer {
  &__image {
    position: absolute;
    bottom: -120px;
    left: -150px;
    width: 495px;
    height: 460px;

    @include screen-md-max {
      display: none;
    }
  }
}
