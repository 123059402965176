.hamburger-menu {
  background-color: green;
  width: 50px;
  height: 50px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-block: 15px;
  padding-inline: 10px;
  display: none;
  border: none;

  @include screen-md-max {
    display: flex;
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: white;
    border-radius: 4px;
  }
}

.mobile-menu {
  position: fixed;
  width: 100vw;
  max-width: 440px;
  height: 100vh;
  left: -100vw;
  top: 0;
  background-color: white;
  z-index: 3;
  transition: left 0.2s ease-in-out;
  padding: 20px 20px 70px 20px;
  overflow: hidden;
  border-right: 1px solid #e1e1e1;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100px;
    width: 100%;
    background: #f0f1ef;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 49px;
    height: 90px;
    width: 100%;
    background: #f0f1ef;
    transform: rotate(-10deg);
    border-bottom-left-radius: 120px;
    z-index: -1;
  }

  &.is-visible {
    overflow-y: scroll;
    left: 0;
  }

  &__logo {
    z-index: 1;
    margin-left: 20px;
  }

  &__close {
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 2;
  }

  &__content {
    margin-top: 110px;
    font-size: 16px;
    font-weight: 500;

    &__link {
      border-radius: 5px;
      width: 100%;
      padding: 5px;
      display: block;

      &:active {
        background-color: #f8f8f8;
      }
    }

    li + li {
      margin-top: 10px;
    }
  }
}
