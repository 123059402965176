$screen-xxl-min: 1770px;
$screen-xl-min: 1200px;
$screen-xl-max: ($screen-xxl-min - 1);
$screen-lg-min: 992px;
$screen-lg-max: ($screen-xl-min - 1);
$screen-md-min: 768px;
$screen-md-max: ($screen-lg-min - 1);
$screen-sm-min: 576px;
$screen-sm-max: ($screen-md-min - 1);
$screen-xs-min: 480px;
$screen-xs-max: ($screen-sm-min - 1);

@mixin screen-xs-min {
  @media (min-width: #{$screen-xs-min}) {
    @content;
  }
}

@mixin screen-xs-max {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin screen-sm-min {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin screen-sm-max {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin screen-md-min {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin screen-md-max {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin screen-lg-min {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin screen-lg-max {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin screen-xl-min {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin screen-xl-max {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin screen-xxl-min {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}
