.sidebar {
  &__contact-card {
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    width: fit-content;
    display: flex;
    padding: 20px 20px 10px 50px;
    border: 1px solid $warning;
    margin: 15px 0;

    @include screen-md-max {
      width: 100%;
    }
  }

  &__categories {
    &__item {
      border-bottom: 1px solid #d9e2c7;
    }

    &__link {
      &:hover {
        color: $warning;
      }
    }
  }
}
.collapse-icon {
  transition: transform 200ms linear;
}

.collapse-icon[aria-expanded='true'] {
  transform: rotate(90deg);
}
