.custom-input {
  display: block;
  background-color: $gray-light;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 42px;
  color: $primary;

  &:focus-visible {
    outline: none;
  }
}

.form-check-input {
  width: 20px;
  height: 20px;
  padding: 10px;

  &:checked {
    background-color: $success;
  }
}

.form-check-label {
  padding-top: 4px;
  padding-left: 10px;
  cursor: pointer;
}
