.font-size-9 {
  font-size: 9.1px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-34 {
  font-size: 34px;
}
