.Toastify {
  &__toast {
    &--success {
      * {
        color: #fff !important;
      }

      background: $success !important;
    }

    &--error {
      * {
        color: #fff !important;
      }

      background: $danger !important;
    }
  }

  &__close-button {
    color: #fff !important;
  }
}
