.header {
  &__link {
    font-weight: 400;
    font-size: 16px;

    &:hover {
      color: $warning;
    }
  }
}

.rounded-shape {
  border-bottom-right-radius: 45%;
  border-top-left-radius: 45%;
}

.cart__badge {
  color: white;
  padding: 0 4px;
  position: absolute;
  left: 40px;
  top: 6px;
  font-size: 10px;

  @include screen-xs-max {
    left: 27px;
  }
}
