.product-details {
  &__gallery {
    height: 450px;

    @include screen-lg-max {
      height: 365px;
      margin-bottom: 105px;
    }

    @include screen-sm-max {
      margin-bottom: 85px;
    }

    .image-gallery-slides {
      border-bottom-right-radius: 25px;
      border-top-left-radius: 25px;
    }

    .image-gallery-image {
      object-fit: cover;
    }

    .fullscreen {
      .image-gallery-image {
        object-fit: contain;
      }

      .product-details__gallery__next-button,
      .product-details__gallery__previous-button {
        top: 50%;
        height: 38px;
        transform: translateY(-50%);
      }

      .product-details__gallery__next-button {
        right: 10px;
      }

      .product-details__gallery__previous-button {
        left: 10px;
      }

      .image-gallery-slide {
        height: calc(100% - 45px);
      }

      .product-details__gallery__full-screen {
        top: 10px;
        right: 10px;
        bottom: auto;
        left: auto;
        color: white !important;
        opacity: 1;
        font-size: 41px;
      }

      .image-gallery-swipe,
      .image-gallery-slides,
      .image-gallery-slide {
        height: 100%;
      }

      .image-gallery-content,
      .image-gallery-slide-wrapper {
        height: calc(100% - 95px);
      }
    }

    .image-gallery-swipe,
    .image-gallery-image,
    .image-gallery-slide,
    .image-gallery-slides,
    .image-gallery-content,
    .image-gallery-slide-wrapper {
      height: 100%;
    }

    .image-gallery-thumbnail {
      &.active,
      &:hover {
        border: 0;
      }
    }

    &__next-button,
    &__previous-button {
      position: absolute;
      bottom: -70px;
      z-index: 1;

      &:disabled {
        opacity: 0.6;
        background-color: #aaaaaa;
      }
    }

    &__next-button {
      right: -17px;

      @include screen-md-max {
        bottom: auto;
        top: 50%;
        right: 5px;
      }
    }

    &__previous-button {
      left: -17px;

      @include screen-md-max {
        bottom: auto;
        top: 50%;
        left: 5px;
      }
    }

    &__full-screen {
      position: absolute;
      right: 16px;
      bottom: 18px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.category-details {
  &__price-range {
    .rc-slider {
      margin-left: 10px;
      padding-top: 15px;
    }

    .rc-slider-track {
      background-color: #e9e9e9;
    }

    .rc-slider-handle-1,
    .rc-slider-handle-2 {
      top: 9px;
    }

    .rc-slider-handle {
      opacity: 100%;
      border: 0;
      border-radius: 5px 0 5px 0;
      background-color: $success;
      height: 25px;
      width: 25px;

      &:active {
        border: none !important;
        box-shadow: 0 0 2px $success !important;
      }
    }
  }
}

.product-card {
  &__order-button {
    font-size: 13px;

    @include screen-lg-max {
      font-size: 11px;
      height: 44px;
    }
  }
}
