.search {
  background-color: $white-smoke;
  width: 300px;

  @include screen-lg-max {
    width: auto;
  }

  @include screen-xs-max {
    width: 170px;
    max-width: 43vw;
  }

  &__input {
    width: 100%;
    margin-left: 20px;
    border: none;
    background-color: $white-smoke;
    height: 47px;

    &:focus-visible {
      outline: none;
    }
  }
}
