.paginator {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;

  &__page {
    width: 15px;
    color: $warning;

    &--active {
      color: $success;
      font-weight: bold;
    }
  }
}
