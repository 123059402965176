// Breakpoints
$screen-xxl-min: 1770px;
$screen-xl-min: 1200px;
$screen-xl-max: ($screen-xxl-min - 1);
$screen-lg-min: 992px;
$screen-lg-max: ($screen-xl-min - 1);
$screen-md-min: 768px;
$screen-md-max: ($screen-lg-min - 1);
$screen-sm-min: 576px;
$screen-sm-max: ($screen-md-min - 1);
$screen-xs-min: 480px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-xxs-max: 375px;

// Colors
$white-smoke: #f8f8f8;
$orange-light: #fbf1e8;
$green-light: #cbd8b1;
$gray-light: #f8f8f8;

// Bootstrap palette
$primary: #4b4b4b;
$success: #409b4d;
$warning: #d8640b;

$enable-negative-margins: true;
