@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

html {
  scroll-behavior: auto !important;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  overscroll-behavior: contain;

  * {
    font-family: 'Montserrat', sans-serif;
    color: $primary;
  }

  @include screen-lg-max {
    font-size: 12px;
  }

  &.block-scroll {
    overflow: hidden;
    touch-action: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
}

.bg-white-smoke {
  background-color: $white-smoke;
}

.bg-light-gray {
  background-color: $gray-light;
}

.bg-orange-light {
  background-color: $orange-light;
}

.border-green-light {
  border-color: $green-light !important;
  border: 1px solid;
}

.border-green {
  border-color: $green !important;
  border: 1px solid;
}

.border-small {
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
}

.border-small-mirror {
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
}

.border-medium {
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
}

.border-large {
  border-bottom-right-radius: 35px;
  border-top-left-radius: 35px;
}

.fw-semi-bold {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.btn:hover {
  opacity: 0.75;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
