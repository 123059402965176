.fade-in {
  animation: 0.5s fade-in forwards;
}

.fade-out {
  animation: 0.5s fade-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
