.home {
  &__statement {
    &__card {
      border: 1px solid $success;
      width: 300px;
      height: 72px;

      @include screen-md-max {
        width: 100%;

        & + & {
          margin-top: 10px;
        }
      }
    }
  }

  &__hint {
    background-color: $warning;
    border-radius: 25px;
    position: relative;
    border-bottom-right-radius: 0;
    padding: 20px;
    height: 180px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: -50px;
      left: 0;
      width: 100%;
      height: 50px;
    }

    &:before {
      background-color: $warning;
    }

    &:after {
      background-color: white;
      border-top-right-radius: 50px;
    }
  }

  &__gallery {
    position: relative;

    .react-multi-carousel-item {
      padding-right: 10px;
    }

    .react-multi-carousel-list {
      overflow: initial;
      overflow-x: hidden;
    }

    &__previous-button {
      position: absolute;
      top: -60px;
      right: 50px;
      margin-right: 10px;

      &:hover {
        opacity: 0.75;
      }
    }

    &__next-button {
      position: absolute;
      right: 15px;
      top: -60px;

      &:hover {
        opacity: 0.75;
      }
    }

    &__image {
      width: 150px;
      border-bottom-right-radius: 25px;
      border-top-left-radius: 25px;

      @include screen-lg-max {
        width: 90%;
      }
    }
  }
}
