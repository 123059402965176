.progress {
  background-color: $primary;

  &__wrapper {
    max-width: 100%;
    width: 760px;
    margin-left: 80px;
    position: relative;
    margin-top: 15px;

    @include screen-md-max {
      width: 100%;
      margin-left: 0;
    }
  }

  &__item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: white;
    transition: background-color 200ms linear 200ms;

    &__circle__second {
      left: 46%;
    }

    &__text {
      font-size: 13px;
      font-weight: 500;
      position: absolute;
      top: 25px;

      @include screen-sm-max {
        display: none;
      }

      &.is-first {
        left: -20px;
      }

      &.is-second {
        left: 49%;
        transform: translateX(-50%);
      }

      &.is-third {
        right: -35px;
      }
    }

    &.is-completed {
      background-color: $warning;
    }
  }
}

.cart {
  &__purchases-table {
    &__qty {
      width: 65px;
      text-align: center;
      margin-left: auto;
    }

    &__row > * {
      @include screen-sm-max {
        border-bottom: 0;
      }
    }
  }

  &__coupon {
    &__input {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 330px;
      padding-right: 120px !important;
      font-size: 13px;
    }

    &__confirm-button {
      position: absolute;
      right: -1px;
      height: 42px;
      top: 0;
    }
  }
}
