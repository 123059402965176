.blog {
  &__card {
    &__image {
      width: 100%;
      max-width: 300px;
      object-fit: cover;

      @include screen-sm-max {
        max-width: 90%;
      }
    }
  }
}
